import React from "react"
import { Link } from "gatsby"
import SEO from "../components/SEO/SEO"
import Layout from "../components/Layout/Layout"
import Button from "@material/react-button"

import heroImage from "../images/logo-main-image.png"

// import { graphql } from "gatsby"

// Styles
import "../styles/app.scss"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO />
        <section className="anoun-home--section1">
          <h3><span className="anoun-title">QA UNIT</span></h3>
          <ul></ul>
          <img src={heroImage} alt="anoun-hero" />          
          {/* <ul></ul>
          <Link to="/contact/">
            <Button raised className="mdc-button--round">
              Contact Us
            </Button>
          </Link>
          <ul></ul> */}
        </section>
        <section className="anoun-home--section2">
          <h3>Users complain about your software?</h3>
          <h3>Is your app getting worse and worse?</h3>
          <h3>You feel that your development team underperform?</h3>
          <h3>So <strong>you</strong> have come to the right place</h3>
        </section>
        <section className="anoun-home--section3">
          <h2>First quote is free</h2>
          <h4>For over a dozen minutes of your time, you can significantly improve your results.</h4>
          <h4>Describe your problem or specify what you want to achieve and you will receive a free quote from us!</h4>
          <Link to="/contact/">
            <Button raised className="mdc-button--round">
              Contact Us
            </Button>
          </Link>
        </section>
      </Layout>
    )
  }
}

export default IndexPage

// export const indexQuery = graphql`
//          query {
//            allMarkdownRemark(
//              filter: { fileAbsolutePath: { regex: "/posts/" } }
//              sort: { fields: [frontmatter___date], order: DESC }
//            ) {
//              edges {
//                node {
//                  excerpt
//                  fields {
//                    slug
//                  }
//                  frontmatter {
//                    date(formatString: "MMMM DD, YYYY")
//                    title
//                    featured_image {
//                      childImageSharp {
//                        fluid(maxWidth: 1200, quality: 92) {
//                          ...GatsbyImageSharpFluid_withWebp
//                        }
//                      }
//                    }
//                  }
//                }
//              }
//            }
//          }
//        `
